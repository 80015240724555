import { motion } from 'framer-motion';

const animations = {
  initial: { opacity: 0, x: -100 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: 100 },
};

const AnimatedPage = ({ children }: any) => {
  return (
    <motion.div
      variants={animations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ ease: 'easeOut', duration: 1.2, delay: 0.2 }}
    >
      {children}
    </motion.div>
  );
};

export default AnimatedPage;
